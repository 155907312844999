<template>
  <v-form ref="form" lazy-validation>
    <v-card>
      <v-card-title class="card-title-actions">
        <v-spacer />
        <vx-btn width="100" outlined color="light" class="ml-2" @click="$previous()">Cancel</vx-btn>
        <vx-btn width="100" outlined color="light" class="ml-2" :loading="loading" @click="$validate(save)">
          Save
        </vx-btn>
        <vx-btn outlined color="light" class="ml-2" :loading="loading" @click="$validate(save, 'previous')">
          Save & close
        </vx-btn>
        <vx-btn outlined color="light" class="ml-2" :loading="loading" @click="$validate(save, 'next')">
          Save & next
        </vx-btn>
      </v-card-title>

      <v-card-text class="pt-8">
        <v-row>
          <v-col cols="12" sm="6" class="py-0">
            <vx-input
              v-model="tag.name"
              type="text"
              name="name"
              label="Name"
              required
              @change="tag.setSlug(tag.slug || tag.name)"
            />
          </v-col>

          <v-col cols="12" sm="6" class="py-0">
            <vx-input
              v-model="tag.slug"
              type="text"
              name="slug"
              label="Slug"
              required
              :disabled="$route.params.id && !dev"
              @change="tag.setSlug(tag.slug)"
            />
          </v-col>

          <!-- <v-col cols="12" sm="6" class="py-0">
            <vx-input type="text" v-model="tag.for" name="for" label="For" required />
          </v-col> -->

          <v-col cols="12" sm="6" class="py-0">
            <vx-input v-model="tag.type" type="select" name="type" label="Type" :items="TAG_TYPES" required />
          </v-col>

          <!-- <v-col cols="12" sm="6" class="py-0">
            <vx-input type="text" v-model="tag.icon" name="icon" label="Icon" required />
          </v-col> -->

          <v-col cols="12" sm="6" class="py-0">
            <vx-input v-model="tag.isActive" type="checkbox" label="Is Enabled?" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import { Tag } from '@tutti/models';
import { TagService } from '@tutti/services';
import { TAG_TYPES } from '@tutti/constants';

export default {
  name: 'LandingPageEdit',

  data() {
    return {
      tab: 0,
      loading: false,
      created: false,
      tag: new Tag(),
      TAG_TYPES,
    };
  },

  async created() {
    this.tab = this.$route?.params?.tab || 0;

    await this.getRole();
    this.created = true;
  },

  methods: {
    async getRole() {
      if (this.$route.params.id) {
        const response = await TagService.getById(this.$route.params.id);

        if (response) {
          this.tag = new Tag(response.data);
          this.$setTitle(this.tag.name);
        }
      }
    },

    async save(action) {
      this.loading = true;
      const response = await TagService.save(this.tag.get());
      if (response) {
        if (action === 'next') {
          this.tag = new Tag();
          this.$reset();
          this.$setTitle('');
        } else if (action === 'previous') {
          this.$previous();
        } else if (response.data._id) {
          this.$replace(this.$toEdit('tag', response.data._id));
        }
      }
      this.loading = false;
    },
  },
};
</script>
