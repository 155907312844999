var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "card-title-actions"
  }, [_c('v-spacer'), _c('vx-btn', {
    staticClass: "ml-2",
    attrs: {
      "width": "100",
      "outlined": "",
      "color": "light"
    },
    on: {
      "click": function click($event) {
        return _vm.$previous();
      }
    }
  }, [_vm._v("Cancel")]), _c('vx-btn', {
    staticClass: "ml-2",
    attrs: {
      "width": "100",
      "outlined": "",
      "color": "light",
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        return _vm.$validate(_vm.save);
      }
    }
  }, [_vm._v(" Save ")]), _c('vx-btn', {
    staticClass: "ml-2",
    attrs: {
      "outlined": "",
      "color": "light",
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        return _vm.$validate(_vm.save, 'previous');
      }
    }
  }, [_vm._v(" Save & close ")]), _c('vx-btn', {
    staticClass: "ml-2",
    attrs: {
      "outlined": "",
      "color": "light",
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        return _vm.$validate(_vm.save, 'next');
      }
    }
  }, [_vm._v(" Save & next ")])], 1), _c('v-card-text', {
    staticClass: "pt-8"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "text",
      "name": "name",
      "label": "Name",
      "required": ""
    },
    on: {
      "change": function change($event) {
        return _vm.tag.setSlug(_vm.tag.slug || _vm.tag.name);
      }
    },
    model: {
      value: _vm.tag.name,
      callback: function callback($$v) {
        _vm.$set(_vm.tag, "name", $$v);
      },
      expression: "tag.name"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "text",
      "name": "slug",
      "label": "Slug",
      "required": "",
      "disabled": _vm.$route.params.id && !_vm.dev
    },
    on: {
      "change": function change($event) {
        return _vm.tag.setSlug(_vm.tag.slug);
      }
    },
    model: {
      value: _vm.tag.slug,
      callback: function callback($$v) {
        _vm.$set(_vm.tag, "slug", $$v);
      },
      expression: "tag.slug"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "select",
      "name": "type",
      "label": "Type",
      "items": _vm.TAG_TYPES,
      "required": ""
    },
    model: {
      value: _vm.tag.type,
      callback: function callback($$v) {
        _vm.$set(_vm.tag, "type", $$v);
      },
      expression: "tag.type"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "checkbox",
      "label": "Is Enabled?"
    },
    model: {
      value: _vm.tag.isActive,
      callback: function callback($$v) {
        _vm.$set(_vm.tag, "isActive", $$v);
      },
      expression: "tag.isActive"
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }